<template>
	<div class="container">
        <calculator-merchandise-price></calculator-merchandise-price>

		<calculator-break-even-fuel-price></calculator-break-even-fuel-price>

        <calculator-fuel-price></calculator-fuel-price>
		<div class="veecli-spacer-row"></div> 
        <b-message class="is-info">
            These calculators are for informational purposes only and should not be used to prepare official tax returns or any other government documents. VEECLi does not guarantee the accuracy of the calculated amounts, which should not be relied upon when determining payments owed to federal, state, or local tax authorities.
        </b-message>  
	</div>
</template>

<script>

import CalculatorMerchandisePrice from "../../../components/app/CalculatorMerchandisePrice"
import CalculatorBreakEvenFuelPrice from "../../../components/app/CalculatorBreakEvenFuelPrice"
import CalculatorFuelPrice from "../../../components/app/CalculatorFuelPrice"

export default {
	components: {
		CalculatorMerchandisePrice,
		CalculatorBreakEvenFuelPrice,
		CalculatorFuelPrice
	},

	data() {
		return {
			pageVersion: "1.0.1",
			pageName: "Veecli-Calculators",
			introSteps: [
			{
				title: "Merchandise Retail Price Calculator",
				element: "#intro-calc-merch-head"
			},
			{
				title: 'Fule Retail Price Calculator',
				intro: 'Most accurate Fuel Retail/Break Even Price Calculator.  Update various Taxes and Fees and save.'
			},
			{
				element: document.querySelector('.calchint1'),
				// intro: 'This step focuses on an image'
			},
			{
				title: 'Farewell!',
				element: document.querySelector('.calchint2'),
				intro: 'And this is our final step!'
			},
			{
				// title: 'Farewell!',
				element: document.querySelector('.calchint3'),
				// intro: 'And this is our final step!'
			}]
		}
	},

	mounted() {
		this.showIntro(this.pageName,this.pageVersion,this.introSteps)
	}
}
</script>